<template>
  <v-dialog
    v-model="show"
    max-width="400px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        dark
        color="warning"
      >
        <v-toolbar-title>
          Atenção!
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <p class="body-1">
          Isso irá resultar em uma cobrança referente a cada SMS enviado!
        </p>
        <p class="body-2">
          Clique novamente em <b>ENVIAR</b> para confirmar
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue"
          outlined
          :loading="loading"
          @click="$emit('send')"
        >
          <v-icon
            left
            v-text="'mdi-send'"
          />
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return { }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

  }
</script>
